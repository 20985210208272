<template>
    <div>
        <div
            class="call-type"
            :class="{ 'text-danger': type === 'Preferencial' }"
        >
            {{ type }}
        </div>
        <div class="call-pass">
            &nbsp;
            <Transition name="slide-fade">
                <span v-if="showCall">{{ pass }}</span>
            </Transition>
            &nbsp;
        </div>
        <div class="call-position">{{ position }}</div>
        <div class="call-group">{{ group }}</div>
        <audio
            ref="audioPanel"
            controls
            src="/assets/quito-mariscal-sucre.wav"
            allow="autoplay"
            style="width: 1px; height: 1px"
        ></audio>
        <img
            src="@/assets/cdt-light.svg"
            width="100px"
            class="position-absolute float-left"
            style="margin-top: 50px"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "call-panel",

    data() {
        return {
            showCall: true,
            type: null,
            pass: null,
            position: null,
            group: null,
        };
    },

    computed: {
        ...mapGetters(["getSocket"]),
    },

    methods: {
        receiveCall(message) {
            try {
                const call = JSON.parse(message.data);

                this.type = call.tipo;
                this.pass = call.senha;
                this.position = call.mesa;
                this.group = call.tipoSenha;

                this.newCall();
            } catch (e) {
                console.error(e);
            }
        },
        newCall() {
            this.showCall = false;

            setTimeout(() => {
                this.showCall = true;
                this.playSound();
            }, 1000);
        },
        playSound() {
            try {
                this.$refs.audioPanel.play();
            } catch (e) {
                console.error(e);
            }
        },
    },

    mounted() {
        this.$options.sockets.onmessage = this.receiveCall;
    },

    beforeDestroy() {
        delete this.$options.sockets.onmessage;
    },
};
</script>
