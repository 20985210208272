<template>
    <div class="pb-3">
        <div class="card p-3">
            <div>
                <div class="row">
                    <div class="col-8 last-group">{{ call.tipoSenha }}</div>
                    <div class="col-4 last-pass">{{ call.senha }}</div>
                </div>
            </div>
            <div class="last-position">{{call.mesa }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'last-call',

    props: ['call']
}
</script>

<style scoped>
.last-pass {
    font-size: 40px;
    font-weight: bold;
    text-align: right;
    padding-right: 20px;
}
.last-position, .last-group {
    font-size: 30px;
}
</style>