import VueNativeSock from 'vue-native-websocket'
import Vue from 'vue'
import App from './App.vue'
import store from './store'

Vue.config.productionTip = false

// TODO: Add variable to enviroment 
Vue.use(VueNativeSock, process.env.VUE_APP_WS, {
  format: 'json',
  store,
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
})

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
