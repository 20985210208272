<template>
    <div id="app" style="height: 100vh">
        <Painel />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import Painel from '@/components/Painel.vue'

export default {
    name: "App",

    components: {
        Painel,
    },
};
</script>

<style>
body {
    --tw-bg-opacity: 1;
    background-color: rgb(17 24 39 / var(--tw-bg-opacity));
    overflow: hidden;
}
.card {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
</style>
