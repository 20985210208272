import Vue from 'vue'
import Vuex from 'vuex'
//import axios from 'axios'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        socket: {
            isConnected: false,
            message: '',
            reconnectError: false,
        },
        calls: [],
        weatherData: null
    },
    getters: {
        getCalls: state => state.calls,
        getSocket: state => state.socket,
        getWeather: state => state.weatherData
    },
    mutations: {
        SOCKET_ONOPEN(state, event) {
            Vue.prototype.$socket = event.currentTarget
            state.socket.isConnected = true
        },
        SOCKET_ONCLOSE(state) {
            state.socket.isConnected = false
        },
        SOCKET_ONERROR(state, event) {
            console.error(state, event)
        },
        // default handler called for all methods
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message
            state.calls.push(message)
        },
        // mutations for reconnect methods
        SOCKET_RECONNECT(state, count) {
            console.info(state, count)
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        },
        SET_WEATHER(state, value) {
            state.weatherData = value
        }
    },
    actions: {
        sendMessage: function (context, message) {
            Vue.prototype.$socket.send(message)
        },
        async getWeatherData({ commit }) {
            try {
                /*const response = await axios.get(
                  `${process.env.VUE_APP_BACKEND}/weather`
                )
        
                if (response.status === 200) {
                    commit('SET_WEATHER', response.data)
                }*/
               commit('SET_WEATHER', {
                "location": {
                  "name": "San Paulo",
                  "region": "Sao Paulo",
                  "country": "Brazil",
                  "lat": -23.53,
                  "lon": -46.62,
                  "tz_id": "America/Sao_Paulo",
                  "localtime_epoch": 1726688698,
                  "localtime": "2024-09-18 16:44"
                },
                "current": {
                  "last_updated_epoch": 1726687800,
                  "last_updated": "2024-09-18 16:30",
                  "temp_c": 21.4,
                  "temp_f": 70.5,
                  "is_day": 1,
                  "condition": {
                    "text": "Parcialmente nublado",
                    "icon": "//cdn.weatherapi.com/weather/64x64/day/116.png",
                    "code": 1003
                  },
                  "wind_mph": 10.5,
                  "wind_kph": 16.9,
                  "wind_degree": 152,
                  "wind_dir": "SSE",
                  "pressure_mb": 1019,
                  "pressure_in": 30.09,
                  "precip_mm": 0,
                  "precip_in": 0,
                  "humidity": 64,
                  "cloud": 50,
                  "feelslike_c": 21.4,
                  "feelslike_f": 70.5,
                  "windchill_c": 21.3,
                  "windchill_f": 70.4,
                  "heatindex_c": 24.5,
                  "heatindex_f": 76,
                  "dewpoint_c": 14,
                  "dewpoint_f": 57.2,
                  "vis_km": 10,
                  "vis_miles": 6,
                  "uv": 7,
                  "gust_mph": 12.1,
                  "gust_kph": 19.5
                },
                "forecast": {
                  "forecastday": [
                    {
                      "date": "2024-09-18",
                      "date_epoch": 1726617600,
                      "day": {
                        "maxtemp_c": 23.4,
                        "maxtemp_f": 74.1,
                        "mintemp_c": 14.4,
                        "mintemp_f": 57.9,
                        "avgtemp_c": 18.5,
                        "avgtemp_f": 65.2,
                        "maxwind_mph": 11.9,
                        "maxwind_kph": 19.1,
                        "totalprecip_mm": 0.02,
                        "totalprecip_in": 0,
                        "totalsnow_cm": 0,
                        "avgvis_km": 10,
                        "avgvis_miles": 6,
                        "avghumidity": 71,
                        "daily_will_it_rain": 0,
                        "daily_chance_of_rain": 0,
                        "daily_will_it_snow": 0,
                        "daily_chance_of_snow": 0,
                        "condition": {
                          "text": "Parcialmente nublado",
                          "icon": "//cdn.weatherapi.com/weather/64x64/day/116.png",
                          "code": 1003
                        },
                        "uv": 6
                      },
                      "astro": {
                        "sunrise": "06:00 AM",
                        "sunset": "06:01 PM",
                        "moonrise": "06:48 PM",
                        "moonset": "06:16 AM",
                        "moon_phase": "Full Moon",
                        "moon_illumination": 100,
                        "is_moon_up": 1,
                        "is_sun_up": 0
                      },
                      "hour": [
                        {
                          "time_epoch": 1726628400,
                          "time": "2024-09-18 00:00",
                          "temp_c": 14.6,
                          "temp_f": 58.2,
                          "is_day": 0,
                          "condition": {
                            "text": "Nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/119.png",
                            "code": 1006
                          },
                          "wind_mph": 9.8,
                          "wind_kph": 15.8,
                          "wind_degree": 112,
                          "wind_dir": "ESE",
                          "pressure_mb": 1022,
                          "pressure_in": 30.18,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 85,
                          "cloud": 79,
                          "feelslike_c": 13.5,
                          "feelslike_f": 56.2,
                          "windchill_c": 13.5,
                          "windchill_f": 56.2,
                          "heatindex_c": 14.6,
                          "heatindex_f": 58.2,
                          "dewpoint_c": 12.1,
                          "dewpoint_f": 53.8,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 11.3,
                          "gust_kph": 18.2,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726632000,
                          "time": "2024-09-18 01:00",
                          "temp_c": 14.4,
                          "temp_f": 58,
                          "is_day": 0,
                          "condition": {
                            "text": "Nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/119.png",
                            "code": 1006
                          },
                          "wind_mph": 9.6,
                          "wind_kph": 15.5,
                          "wind_degree": 108,
                          "wind_dir": "ESE",
                          "pressure_mb": 1022,
                          "pressure_in": 30.17,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 86,
                          "cloud": 81,
                          "feelslike_c": 13.3,
                          "feelslike_f": 56,
                          "windchill_c": 13.3,
                          "windchill_f": 56,
                          "heatindex_c": 14.4,
                          "heatindex_f": 58,
                          "dewpoint_c": 12.1,
                          "dewpoint_f": 53.7,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 11.1,
                          "gust_kph": 17.8,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726635600,
                          "time": "2024-09-18 02:00",
                          "temp_c": 14.5,
                          "temp_f": 58.2,
                          "is_day": 0,
                          "condition": {
                            "text": "Nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/119.png",
                            "code": 1006
                          },
                          "wind_mph": 9.4,
                          "wind_kph": 15.1,
                          "wind_degree": 105,
                          "wind_dir": "ESE",
                          "pressure_mb": 1021,
                          "pressure_in": 30.16,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 84,
                          "cloud": 70,
                          "feelslike_c": 13.5,
                          "feelslike_f": 56.3,
                          "windchill_c": 13.5,
                          "windchill_f": 56.3,
                          "heatindex_c": 14.5,
                          "heatindex_f": 58.2,
                          "dewpoint_c": 11.9,
                          "dewpoint_f": 53.5,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 10.8,
                          "gust_kph": 17.4,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726639200,
                          "time": "2024-09-18 03:00",
                          "temp_c": 14.9,
                          "temp_f": 58.8,
                          "is_day": 0,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/116.png",
                            "code": 1003
                          },
                          "wind_mph": 9.8,
                          "wind_kph": 15.8,
                          "wind_degree": 101,
                          "wind_dir": "ESE",
                          "pressure_mb": 1021,
                          "pressure_in": 30.15,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 81,
                          "cloud": 56,
                          "feelslike_c": 13.9,
                          "feelslike_f": 56.9,
                          "windchill_c": 13.9,
                          "windchill_f": 56.9,
                          "heatindex_c": 14.9,
                          "heatindex_f": 58.8,
                          "dewpoint_c": 11.7,
                          "dewpoint_f": 53,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 11.3,
                          "gust_kph": 18.2,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726642800,
                          "time": "2024-09-18 04:00",
                          "temp_c": 15.3,
                          "temp_f": 59.5,
                          "is_day": 0,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/116.png",
                            "code": 1003
                          },
                          "wind_mph": 9.4,
                          "wind_kph": 15.1,
                          "wind_degree": 96,
                          "wind_dir": "E",
                          "pressure_mb": 1021,
                          "pressure_in": 30.14,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 78,
                          "cloud": 32,
                          "feelslike_c": 15.3,
                          "feelslike_f": 59.5,
                          "windchill_c": 15.3,
                          "windchill_f": 59.5,
                          "heatindex_c": 15.3,
                          "heatindex_f": 59.5,
                          "dewpoint_c": 11.5,
                          "dewpoint_f": 52.6,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 10.8,
                          "gust_kph": 17.4,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726646400,
                          "time": "2024-09-18 05:00",
                          "temp_c": 14.4,
                          "temp_f": 57.9,
                          "is_day": 0,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/116.png",
                            "code": 1003
                          },
                          "wind_mph": 6.7,
                          "wind_kph": 10.8,
                          "wind_degree": 100,
                          "wind_dir": "E",
                          "pressure_mb": 1021,
                          "pressure_in": 30.15,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 84,
                          "cloud": 29,
                          "feelslike_c": 13.8,
                          "feelslike_f": 56.8,
                          "windchill_c": 13.8,
                          "windchill_f": 56.8,
                          "heatindex_c": 14.4,
                          "heatindex_f": 57.9,
                          "dewpoint_c": 11.8,
                          "dewpoint_f": 53.3,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 8.3,
                          "gust_kph": 13.4,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726650000,
                          "time": "2024-09-18 06:00",
                          "temp_c": 15,
                          "temp_f": 59,
                          "is_day": 0,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/116.png",
                            "code": 1003
                          },
                          "wind_mph": 7.6,
                          "wind_kph": 12.2,
                          "wind_degree": 80,
                          "wind_dir": "E",
                          "pressure_mb": 1021,
                          "pressure_in": 30.16,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 81,
                          "cloud": 31,
                          "feelslike_c": 15,
                          "feelslike_f": 59,
                          "windchill_c": 15,
                          "windchill_f": 59,
                          "heatindex_c": 15,
                          "heatindex_f": 59,
                          "dewpoint_c": 11.7,
                          "dewpoint_f": 53.1,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 8.7,
                          "gust_kph": 14.1,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726653600,
                          "time": "2024-09-18 07:00",
                          "temp_c": 16.8,
                          "temp_f": 62.3,
                          "is_day": 1,
                          "condition": {
                            "text": "Sol",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                            "code": 1000
                          },
                          "wind_mph": 8.1,
                          "wind_kph": 13,
                          "wind_degree": 73,
                          "wind_dir": "ENE",
                          "pressure_mb": 1022,
                          "pressure_in": 30.17,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 72,
                          "cloud": 14,
                          "feelslike_c": 16.8,
                          "feelslike_f": 62.3,
                          "windchill_c": 16.8,
                          "windchill_f": 62.3,
                          "heatindex_c": 16.8,
                          "heatindex_f": 62.3,
                          "dewpoint_c": 11.8,
                          "dewpoint_f": 53.3,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 9.3,
                          "gust_kph": 14.9,
                          "uv": 6
                        },
                        {
                          "time_epoch": 1726657200,
                          "time": "2024-09-18 08:00",
                          "temp_c": 18.9,
                          "temp_f": 66,
                          "is_day": 1,
                          "condition": {
                            "text": "Sol",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                            "code": 1000
                          },
                          "wind_mph": 7.2,
                          "wind_kph": 11.5,
                          "wind_degree": 82,
                          "wind_dir": "E",
                          "pressure_mb": 1021,
                          "pressure_in": 30.16,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 64,
                          "cloud": 9,
                          "feelslike_c": 18.9,
                          "feelslike_f": 66,
                          "windchill_c": 18.9,
                          "windchill_f": 66,
                          "heatindex_c": 18.9,
                          "heatindex_f": 66,
                          "dewpoint_c": 11.9,
                          "dewpoint_f": 53.4,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 8.2,
                          "gust_kph": 13.2,
                          "uv": 6
                        },
                        {
                          "time_epoch": 1726660800,
                          "time": "2024-09-18 09:00",
                          "temp_c": 21,
                          "temp_f": 69.8,
                          "is_day": 1,
                          "condition": {
                            "text": "Sol",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                            "code": 1000
                          },
                          "wind_mph": 6,
                          "wind_kph": 9.7,
                          "wind_degree": 104,
                          "wind_dir": "ESE",
                          "pressure_mb": 1021,
                          "pressure_in": 30.16,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 56,
                          "cloud": 14,
                          "feelslike_c": 21,
                          "feelslike_f": 69.8,
                          "windchill_c": 21,
                          "windchill_f": 69.8,
                          "heatindex_c": 24.4,
                          "heatindex_f": 75.9,
                          "dewpoint_c": 11.8,
                          "dewpoint_f": 53.3,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 7,
                          "gust_kph": 11.2,
                          "uv": 7
                        },
                        {
                          "time_epoch": 1726664400,
                          "time": "2024-09-18 10:00",
                          "temp_c": 22.7,
                          "temp_f": 72.9,
                          "is_day": 1,
                          "condition": {
                            "text": "Sol",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                            "code": 1000
                          },
                          "wind_mph": 6,
                          "wind_kph": 9.7,
                          "wind_degree": 137,
                          "wind_dir": "SE",
                          "pressure_mb": 1021,
                          "pressure_in": 30.14,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 51,
                          "cloud": 24,
                          "feelslike_c": 24.6,
                          "feelslike_f": 76.4,
                          "windchill_c": 22.7,
                          "windchill_f": 72.9,
                          "heatindex_c": 24.6,
                          "heatindex_f": 76.4,
                          "dewpoint_c": 12,
                          "dewpoint_f": 53.7,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 7,
                          "gust_kph": 11.2,
                          "uv": 7
                        },
                        {
                          "time_epoch": 1726668000,
                          "time": "2024-09-18 11:00",
                          "temp_c": 23.4,
                          "temp_f": 74.1,
                          "is_day": 1,
                          "condition": {
                            "text": "Possibilidade de chuva irregular",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/176.png",
                            "code": 1063
                          },
                          "wind_mph": 8.9,
                          "wind_kph": 14.4,
                          "wind_degree": 171,
                          "wind_dir": "S",
                          "pressure_mb": 1020,
                          "pressure_in": 30.13,
                          "precip_mm": 0.01,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 50,
                          "cloud": 87,
                          "feelslike_c": 24.9,
                          "feelslike_f": 76.8,
                          "windchill_c": 23.4,
                          "windchill_f": 74.1,
                          "heatindex_c": 24.9,
                          "heatindex_f": 76.8,
                          "dewpoint_c": 12.4,
                          "dewpoint_f": 54.3,
                          "will_it_rain": 0,
                          "chance_of_rain": 61,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 10.3,
                          "gust_kph": 16.6,
                          "uv": 6
                        },
                        {
                          "time_epoch": 1726671600,
                          "time": "2024-09-18 12:00",
                          "temp_c": 22.8,
                          "temp_f": 73,
                          "is_day": 1,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/116.png",
                            "code": 1003
                          },
                          "wind_mph": 11.9,
                          "wind_kph": 19.1,
                          "wind_degree": 164,
                          "wind_dir": "SSE",
                          "pressure_mb": 1020,
                          "pressure_in": 30.11,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 55,
                          "cloud": 51,
                          "feelslike_c": 24.8,
                          "feelslike_f": 76.6,
                          "windchill_c": 22.8,
                          "windchill_f": 73,
                          "heatindex_c": 24.8,
                          "heatindex_f": 76.6,
                          "dewpoint_c": 13.3,
                          "dewpoint_f": 55.9,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 13.6,
                          "gust_kph": 22,
                          "uv": 7
                        },
                        {
                          "time_epoch": 1726675200,
                          "time": "2024-09-18 13:00",
                          "temp_c": 22.7,
                          "temp_f": 72.9,
                          "is_day": 1,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/116.png",
                            "code": 1003
                          },
                          "wind_mph": 10.1,
                          "wind_kph": 16.2,
                          "wind_degree": 163,
                          "wind_dir": "SSE",
                          "pressure_mb": 1019,
                          "pressure_in": 30.08,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 56,
                          "cloud": 34,
                          "feelslike_c": 24.7,
                          "feelslike_f": 76.5,
                          "windchill_c": 22.7,
                          "windchill_f": 72.9,
                          "heatindex_c": 24.7,
                          "heatindex_f": 76.5,
                          "dewpoint_c": 13.6,
                          "dewpoint_f": 56.4,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 11.6,
                          "gust_kph": 18.6,
                          "uv": 7
                        },
                        {
                          "time_epoch": 1726678800,
                          "time": "2024-09-18 14:00",
                          "temp_c": 22.8,
                          "temp_f": 73.1,
                          "is_day": 1,
                          "condition": {
                            "text": "Sol",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                            "code": 1000
                          },
                          "wind_mph": 8.3,
                          "wind_kph": 13.3,
                          "wind_degree": 160,
                          "wind_dir": "SSE",
                          "pressure_mb": 1018,
                          "pressure_in": 30.06,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 56,
                          "cloud": 17,
                          "feelslike_c": 24.8,
                          "feelslike_f": 76.6,
                          "windchill_c": 22.8,
                          "windchill_f": 73.1,
                          "heatindex_c": 24.8,
                          "heatindex_f": 76.6,
                          "dewpoint_c": 13.5,
                          "dewpoint_f": 56.4,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 9.5,
                          "gust_kph": 15.3,
                          "uv": 7
                        },
                        {
                          "time_epoch": 1726682400,
                          "time": "2024-09-18 15:00",
                          "temp_c": 22.7,
                          "temp_f": 72.9,
                          "is_day": 1,
                          "condition": {
                            "text": "Sol",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                            "code": 1000
                          },
                          "wind_mph": 8.7,
                          "wind_kph": 14,
                          "wind_degree": 153,
                          "wind_dir": "SSE",
                          "pressure_mb": 1018,
                          "pressure_in": 30.05,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 56,
                          "cloud": 9,
                          "feelslike_c": 24.8,
                          "feelslike_f": 76.6,
                          "windchill_c": 22.7,
                          "windchill_f": 72.9,
                          "heatindex_c": 24.8,
                          "heatindex_f": 76.6,
                          "dewpoint_c": 13.7,
                          "dewpoint_f": 56.6,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 10,
                          "gust_kph": 16.2,
                          "uv": 7
                        },
                        {
                          "time_epoch": 1726686000,
                          "time": "2024-09-18 16:00",
                          "temp_c": 21.4,
                          "temp_f": 70.5,
                          "is_day": 1,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/116.png",
                            "code": 1003
                          },
                          "wind_mph": 10.5,
                          "wind_kph": 16.9,
                          "wind_degree": 152,
                          "wind_dir": "SSE",
                          "pressure_mb": 1019,
                          "pressure_in": 30.09,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 64,
                          "cloud": 50,
                          "feelslike_c": 21.3,
                          "feelslike_f": 70.4,
                          "windchill_c": 21.3,
                          "windchill_f": 70.4,
                          "heatindex_c": 24.5,
                          "heatindex_f": 76,
                          "dewpoint_c": 14,
                          "dewpoint_f": 57.2,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 12.1,
                          "gust_kph": 19.5,
                          "uv": 7
                        },
                        {
                          "time_epoch": 1726689600,
                          "time": "2024-09-18 17:00",
                          "temp_c": 19.4,
                          "temp_f": 66.9,
                          "is_day": 1,
                          "condition": {
                            "text": "Sol",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                            "code": 1000
                          },
                          "wind_mph": 10.3,
                          "wind_kph": 16.6,
                          "wind_degree": 148,
                          "wind_dir": "SSE",
                          "pressure_mb": 1019,
                          "pressure_in": 30.08,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 72,
                          "cloud": 8,
                          "feelslike_c": 19.4,
                          "feelslike_f": 66.8,
                          "windchill_c": 19.4,
                          "windchill_f": 66.8,
                          "heatindex_c": 19.4,
                          "heatindex_f": 66.8,
                          "dewpoint_c": 14.2,
                          "dewpoint_f": 57.6,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 11.8,
                          "gust_kph": 19,
                          "uv": 6
                        },
                        {
                          "time_epoch": 1726693200,
                          "time": "2024-09-18 18:00",
                          "temp_c": 18.4,
                          "temp_f": 65.1,
                          "is_day": 1,
                          "condition": {
                            "text": "Sol",
                            "icon": "//cdn.weatherapi.com/weather/64x64/day/113.png",
                            "code": 1000
                          },
                          "wind_mph": 8.9,
                          "wind_kph": 14.4,
                          "wind_degree": 145,
                          "wind_dir": "SE",
                          "pressure_mb": 1019,
                          "pressure_in": 30.1,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 77,
                          "cloud": 24,
                          "feelslike_c": 18.4,
                          "feelslike_f": 65.1,
                          "windchill_c": 18.4,
                          "windchill_f": 65.1,
                          "heatindex_c": 18.4,
                          "heatindex_f": 65.1,
                          "dewpoint_c": 14.2,
                          "dewpoint_f": 57.6,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 10.3,
                          "gust_kph": 16.6,
                          "uv": 6
                        },
                        {
                          "time_epoch": 1726696800,
                          "time": "2024-09-18 19:00",
                          "temp_c": 17.8,
                          "temp_f": 64.1,
                          "is_day": 0,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/116.png",
                            "code": 1003
                          },
                          "wind_mph": 7.8,
                          "wind_kph": 12.6,
                          "wind_degree": 137,
                          "wind_dir": "SE",
                          "pressure_mb": 1020,
                          "pressure_in": 30.11,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 78,
                          "cloud": 30,
                          "feelslike_c": 17.8,
                          "feelslike_f": 64.1,
                          "windchill_c": 17.8,
                          "windchill_f": 64.1,
                          "heatindex_c": 17.8,
                          "heatindex_f": 64.1,
                          "dewpoint_c": 14.1,
                          "dewpoint_f": 57.3,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 9,
                          "gust_kph": 14.5,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726700400,
                          "time": "2024-09-18 20:00",
                          "temp_c": 17.5,
                          "temp_f": 63.6,
                          "is_day": 0,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/116.png",
                            "code": 1003
                          },
                          "wind_mph": 6.7,
                          "wind_kph": 10.8,
                          "wind_degree": 122,
                          "wind_dir": "ESE",
                          "pressure_mb": 1020,
                          "pressure_in": 30.12,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 79,
                          "cloud": 29,
                          "feelslike_c": 17.5,
                          "feelslike_f": 63.6,
                          "windchill_c": 17.5,
                          "windchill_f": 63.6,
                          "heatindex_c": 17.5,
                          "heatindex_f": 63.6,
                          "dewpoint_c": 13.9,
                          "dewpoint_f": 57.1,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 7.7,
                          "gust_kph": 12.4,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726704000,
                          "time": "2024-09-18 21:00",
                          "temp_c": 17.3,
                          "temp_f": 63.2,
                          "is_day": 0,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/116.png",
                            "code": 1003
                          },
                          "wind_mph": 6.5,
                          "wind_kph": 10.4,
                          "wind_degree": 119,
                          "wind_dir": "ESE",
                          "pressure_mb": 1020,
                          "pressure_in": 30.12,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 80,
                          "cloud": 30,
                          "feelslike_c": 17.3,
                          "feelslike_f": 63.2,
                          "windchill_c": 17.3,
                          "windchill_f": 63.2,
                          "heatindex_c": 17.3,
                          "heatindex_f": 63.2,
                          "dewpoint_c": 13.9,
                          "dewpoint_f": 57,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 7.5,
                          "gust_kph": 12,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726707600,
                          "time": "2024-09-18 22:00",
                          "temp_c": 17.2,
                          "temp_f": 62.9,
                          "is_day": 0,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/116.png",
                            "code": 1003
                          },
                          "wind_mph": 5.8,
                          "wind_kph": 9.4,
                          "wind_degree": 110,
                          "wind_dir": "ESE",
                          "pressure_mb": 1020,
                          "pressure_in": 30.11,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 82,
                          "cloud": 27,
                          "feelslike_c": 17.2,
                          "feelslike_f": 62.9,
                          "windchill_c": 17.2,
                          "windchill_f": 62.9,
                          "heatindex_c": 17.2,
                          "heatindex_f": 62.9,
                          "dewpoint_c": 14,
                          "dewpoint_f": 57.2,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 6.7,
                          "gust_kph": 10.8,
                          "uv": 0
                        },
                        {
                          "time_epoch": 1726711200,
                          "time": "2024-09-18 23:00",
                          "temp_c": 16.9,
                          "temp_f": 62.4,
                          "is_day": 0,
                          "condition": {
                            "text": "Parcialmente nublado",
                            "icon": "//cdn.weatherapi.com/weather/64x64/night/116.png",
                            "code": 1003
                          },
                          "wind_mph": 5.6,
                          "wind_kph": 9,
                          "wind_degree": 101,
                          "wind_dir": "ESE",
                          "pressure_mb": 1019,
                          "pressure_in": 30.09,
                          "precip_mm": 0,
                          "precip_in": 0,
                          "snow_cm": 0,
                          "humidity": 82,
                          "cloud": 26,
                          "feelslike_c": 16.9,
                          "feelslike_f": 62.4,
                          "windchill_c": 16.9,
                          "windchill_f": 62.4,
                          "heatindex_c": 16.9,
                          "heatindex_f": 62.4,
                          "dewpoint_c": 13.8,
                          "dewpoint_f": 56.9,
                          "will_it_rain": 0,
                          "chance_of_rain": 0,
                          "will_it_snow": 0,
                          "chance_of_snow": 0,
                          "vis_km": 10,
                          "vis_miles": 6,
                          "gust_mph": 6.6,
                          "gust_kph": 10.5,
                          "uv": 0
                        }
                      ]
                    }
                  ]
                }
              })
            } catch (error) {
                console.error(error)
                alert('You have entered invalid or incorrect data/city name! Please try again.')
            }
        }
    }
})